import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import LabelMaker from '../components/label-maker/label-maker';
import LabelMakerShape from '../components/label-maker/label-maker-shape';
import Layout from '../components/layout';
import SEO from '../components/seo';

const CreateShapePage = ({ intl, location, pageContext }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: `label-maker.title.${pageContext.category}` })} />
    <section className="section">
      <div className="container">
        <FormattedMessage id="label-maker.intro" />
      </div>
    </section>
    <section className="hero is-light">
      <div className="hero-body section" id="label-maker-body">
        <div className="container">
          <h1 className="title is-3">
            <FormattedMessage id={`label-maker.categories.${pageContext.category}.title`} />
          </h1>
          <LabelMaker
            location={location}
            category={pageContext.category}
            categorySlug={pageContext.slug}
          >
            <LabelMakerShape shapes={pageContext.data.shapes} />
          </LabelMaker>
        </div>
      </div>
    </section>
  </Layout>
);

CreateShapePage.propTypes = {
  intl: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

export default injectIntl(CreateShapePage);
