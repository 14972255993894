import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import JSONData from '../../conf/label-maker.json';
import { setShape as setShapeAction } from '../../state/actions';
import { getLabelShape } from '../../state/selectors';
import RadioButton from '../radio-button';

const LabelMakerShape = ({ setShape, currentShape, shapes }) => {
  function setRadio(event) {
    setShape(event.target.value);
  }

  const validShapes = shapes || JSONData.shapes;
  const inputShapes = [];
  validShapes.map((shape) => inputShapes.push(
    <RadioButton name="shape" className="m-sm" value={shape} checked={shape === currentShape} key={shape}>
      <i className={`icon-${shape}-outline icon-xl`} aria-hidden="true" />
    </RadioButton>,
  ));

  if (validShapes.indexOf(currentShape) < 0) setShape(validShapes[0]);

  return (
    <div>
      <div className="notification is-info is-light has-icon">
        <span className="icon">
          <i className="fas fa-info-circle fa-lg" />
        </span>
        <span className="p-l-lg">
          <FormattedMessage id="label-maker.shape.help" />
        </span>
      </div>
      <form>
        <div className="control has-text-centered" onChange={setRadio.bind(this)}>
          {inputShapes}
        </div>
      </form>
    </div>
  );
};

LabelMakerShape.propTypes = {
  shapes: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentShape: PropTypes.string,
  setShape: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentShape: getLabelShape(state),
});

const mapDispatchToProps = {
  setShape: setShapeAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LabelMakerShape));
